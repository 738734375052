import * as XLSX from 'xlsx'

const saveData = (function () {
  const a = document.createElement('a')
  a.target = '_blank'

  // a.style = "display: none"
  return function (url, fileName) {
    a.setAttribute('download', fileName)
    a.href = url
    // a.rel = 'noopener noreferrer'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    // a.click()
    // document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }
}())

export const ExportExcel = (data, sheetName, fileName, fileType = 'xlsx') => {
  // data ---> Array格式
  // [
  //    // JSON 格式
  //    {
  //        欄位1：A數值1, 欄位2：A數值2, 欄位3：A數值3, 欄位4：A數值4 ...
  //    },
  //    {
  //        欄位1：B數值1, 欄位2：B數值2, 欄位3：B數值3, 欄位4：B數值4 ...
  //    },
  //    ...
  // ]
  const ws = XLSX.utils.json_to_sheet(data)

  /* add to workbook */
  const wb = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(wb, ws, sheetName)

  /* generate an XLSX file */
  try {
    // -- base64
    // const buf = XLSX.write(wb, {type: "base64", bookType: "xlsx"})
    // const url = 'data:application/vnd.ms-excel;base64,' + buf
    // saveData(url, fileName)
    // -- buffer
    const buf = XLSX.write(wb, { type: 'buffer', bookType: fileType })
    // const buftype = 'application/vnd.ms-excel;charset=utf-8';
    const buftype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const blob = new Blob([buf], {
      type: buftype,
    })
    const url = URL.createObjectURL(blob)
    saveData(url, `${fileName}.${fileType}`)

    // -- reader
    // const buf = XLSX.write(wb, {type: "buffer", bookType: "xlsx"})
    // const buftype = 'application/vnd.ms-excel;charset=utf-8';
    // const blob = new Blob([buf], {
    //     type: buftype
    // })
    // const reader = new FileReader()
    // reader.onload = function(e){
    //     // console.log('e', e)
    //     // console.log(reader.result)
    //     window.location.href = reader.result
    // }
    // reader.readAsDataURL(blob)

    // XLSX.writeFile(wb, fileName + ".xlsx");
    // const res = XLSX.writeFileXLSX(wb, fileName + ".xlsx")
  } catch (error) {
    console.log('export error', error)
  }
}

export const ExportMoreSheetExcel = async (sheets, fileName) => {
  // [
  //   {
  //     sheetName: '訂單詳細資料',
  //     data: detailData,
  //   },
  //   {
  //     sheetName: '出貨用資料',
  //     data,
  //   },
  // ]
  const wb = XLSX.utils.book_new()
  const count = 1

  for (const sheet of sheets) {
    const ws = XLSX.utils.json_to_sheet(sheet.data)
    sheet.sheetName = sheet.sheetName.replace(/\\/g, '_')
    // eslint-disable-next-line prefer-regex-literals
    sheet.sheetName = sheet.sheetName.replace(new RegExp('[/ ? * \\[\\] ]', 'g'), '_')
    try {
      XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName)
    } catch (error) {
      XLSX.utils.book_append_sheet(wb, ws, sheet.sheetName + count)
    }
  }

  XLSX.writeFile(wb, fileName + '.xlsx')
}
